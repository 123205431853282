import { ready } from "./utils.js";
import Swiper, { Autoplay, Pagination, Navigation } from "swiper";

ready(() => {
  new Swiper("#swiper-hero", {
    modules: [Pagination],
    loop: true,
    centeredSlides: true,
    slidesPerView: 1,
    spaceBetween: 50,
    speed: 500,
    autoHeight: true,

    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      renderBullet: (index, className) => {
        return (
          '<span class="' +
          className +
          '"><span class="swiper-pagination-bar"></span></span>'
        );
      },
    },
  });

  new Swiper("#swiper-us", {
    modules: [Autoplay],
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    centeredSlides: true,
    loop: true,
    slidesPerView: 1,
    spaceBetween: 0,
    allowTouchMove: false,
    speed: 1,
    autoplay: {
      delay: 750,
      disableOnInteraction: false,
      //waitForTransition: true,
    },
  });

  new Swiper("#swiper-us-word", {
    modules: [Autoplay],
    allowTouchMove: false,
    centeredSlides: true,
    loop: true,
    slidesPerView: 1,
    spaceBetween: 0,
    allowTouchMove: false,
    speed: 1,
    autoplay: {
      delay: 1500,
      disableOnInteraction: false,
    },
  });

  new Swiper("#swiper-howWeWork", {
    modules: [Navigation],
    centeredSlides: true,
    loop: true,
    slidesPerView: 1,
    spaceBetween: 20,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    autoHeight: true,
  });

  new Swiper("#swiper-angebot", {
    modules: [Navigation],
    centeredSlides: true,
    loop: true,
    slidesPerView: 1,
    spaceBetween: 20,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    autoHeight: true,
  });

  new Swiper("#swiper-guerti", {
    modules: [Navigation],
    centeredSlides: true,
    slidesPerView: 1,
    spaceBetween: 20,
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      800: {
        spaceBetween: 40,
        centeredSlides: false,
        slidesPerView: 2,
      },
      // 1330: {
      //   slidesPerView: 3,
      //   initialSlide: 1,
      // },
    },
  });
});
